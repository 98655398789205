import { graphql } from 'gatsby';
import React, { useContext } from 'react';
import Header from '../components/layout/Header';
import { MyContext } from '../context';

const NotFound = () => {
  const ctx = useContext(MyContext);
  const Footer = ctx.project.footerComp;
  return (
    <>
      <Header />
      <section className="my-16">
        <div className="md:container md:mx-auto md:px-16">
          <h1>Not Found</h1>
          <p>You just hit a page that doesn&#39;t exist...</p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default NotFound;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
